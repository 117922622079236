import { createRoot } from "react-dom/client"

// normalize CSS across browsers
import "./src/normalize.scss"
// custom CSS styles
import "./src/style.scss"
import "react-datepicker/dist/react-datepicker.css"
// send to piano analytics
import { v4 as uuid } from "uuid"
import * as config from "./config"

export function replaceHydrateFunction() {
  return (element, container) => {
    const root = createRoot(container)
    root.render(element)
  }
}

const sendToPianoAnalytics = () => {
  const clientId = uuid()
  const siteId = parseInt(config.piano_site_id)

  if (isNaN(siteId)) {
    return
  }

  /*pianoAnalytics.setConfigurations({
    site: siteId,
    collectDomain: "https://nssvsmp.pa-cd.com",
  })
  pianoAnalytics.setVisitorId(clientId)
  pianoAnalytics.sendEvent("page.display", {
    page_chapter1: "Portail Innovation",
    page: "index",
  })*/
}

sendToPianoAnalytics()
